.block-404 {
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
}

.block-404__info {
    width: 595px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.block-404__info-text {
    text-align: center;
    font-weight: 600;
}

.block-404__info-button {
    padding: 16px 48px 16px 48px;
    font-weight: 700;
    font-size: 16px;
    color: #2a2a2a;
    background: #ffd737;
    border-radius: 100px;
    border: none;
    text-decoration: none;
}

.block-404__background {
    opacity: 0.4;
}

.block-404 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  position: relative;
}

.block-404__info {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 595px;
  display: flex;
  position: absolute;
}

.block-404__info-text {
  text-align: center;
  font-weight: 600;
}

.block-404__info-button {
  color: #2a2a2a;
  background: #ffd737;
  border: none;
  border-radius: 100px;
  padding: 16px 48px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

.block-404__background {
  opacity: .4;
}
/*# sourceMappingURL=index.28f83944.css.map */
